import Vue from 'vue';
import Vuex from 'vuex';
import { FeathersVuex } from '../feathers-client';
import auth from './store.auth';

Vue.use(Vuex);
Vue.use(Vuex);
Vue.use(FeathersVuex);

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /.js$/
);
const servicePlugins = requireModule
  .keys()
  .map((modulePath) => requireModule(modulePath).default);

const store = new Vuex.Store({
  state: {
    globalAgentesDeComercio: [
      'Supervisoras',
      'Gerente de Region',
      'Gerente de Division',
    ],
    globalLoading: false,
    // prettier-ignore
    OtrasVisitas: [
      { id: 'a', textColorClass: 'black--text', label: 'Pedido' ,border:'#4DD0E1', background:'rgba(77, 208, 225, 0.4)', marker: require('@/assets/images/pedido-icon.png')},
      { id: 'b', textColorClass: 'cyan--text text--lighten-2', label: 'Inicio de labores' ,border:'#4DD0E1', background:'rgba(77, 208, 225, 0.4)', marker: "http://maps.google.com/mapfiles/ms/icons/ltblue-dot.png"},
      { id: 'c', textColorClass: 'deep-purple--text text-darken-1', label: 'Prospecto' ,border:'#5E35B1', background:'rgba(94, 53, 177, 0.4)', marker: "http://maps.google.com/mapfiles/ms/icons/purple-dot.png"},
      { id: 'd', textColorClass: 'light-blue--text text--accent-4', label: 'Nombramiento' ,border:'#0091EA', background:'rgba(0, 145, 234, 0.4)', marker: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"},
      { id: 'e', textColorClass: 'deep-purple--text text-darken-1', label: 'Reunion de consejeras' ,border:'#5E35B1', background:'rgba(94, 53, 177, 0.4)', marker: "http://maps.google.com/mapfiles/ms/icons/purple-dot.png"},
      { id: 'e2', textColorClass: 'deep-purple--text text-darken-1', label: 'Reuniones' ,border:'#5E35B1', background:'rgba(94, 53, 177, 0.4)', marker: "http://maps.google.com/mapfiles/ms/icons/purple-dot.png"},
      { id: 'f', textColorClass: 'red--text text--accent-1', label: 'Inicio de labores 7am' ,border:'#F08080', background:'rgba(255, 160, 122, 0.4)', marker: require('@/assets/images/inic_lab_marker.png')},
      { id: 'g', textColorClass: 'light-green--text text--accent-3', label: 'Ajuste' ,border:'#76FF03', background:'rgba(118, 255, 3, 0.4)', marker: "http://maps.google.com/mapfiles/ms/icons/green-dot.png"},
      { id: 'h', textColorClass: 'light-blue--text text--accent-1', label: 'Cierre de labores 5pm' ,border:'#B3E5FC', background:'rgba(179, 229, 252, 0.4)', marker: require('@/assets/images/cierre_lab_marker.png')},
      { id: 'i', textColorClass: 'dark-blue--text text--accent-1', label: 'Actividades' ,border:'#342FEC', background:'rgba(0, 71, 255, 0.4)', marker: "http://maps.google.com/mapfiles/ms/icons/ltblue-dot.png"},
      
      
    ],
    // datos de tipos de visitas

    visitas: [
      {
        id: 4,
        textColorClass: 'light-green--text text--accent-2',
        label: 'Primer pedido',
        border: '#B2FF59',
        background: 'rgba(178, 255, 89, 0.4)',
        marker: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
      },
      {
        id: 5,
        textColorClass: 'amber--text text--accent-2',
        label: 'Segundo pedido',
        border: '#FFD740',
        background: 'rgba(255, 215, 64, 0.4)',
        marker: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
      },
      {
        id: 6,
        textColorClass: 'red--text text--accent-2',
        label: 'Reingreso',
        border: '#FF5252',
        background: 'rgba(255, 82, 82, 0.4)',
        marker: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
      },
      {
        id: 7,
        textColorClass: 'purple--text text--lighten-1',
        label: 'Posible baja',
        border: '#AB47BC',
        background: 'rgba(171, 71, 188, 0.4)',
        marker: 'http://maps.google.com/mapfiles/ms/icons/pink-dot.png',
      },
      {
        id: 8,
        textColorClass: 'teal--text text-darken-2',
        label: 'Cobro',
        border: '#00796B',
        background: 'rgba(0, 121, 107, 0.4)',
        marker: 'http://maps.google.com/mapfiles/ms/icons/ltblue-dot.png',
      },
      {
        id: 9,
        textColorClass: 'orange--text text-darken-1',
        label: 'Niveles',
        border: '#FB8C00',
        background: 'rgba(251, 140, 0, 0.4)',
        marker: 'http://maps.google.com/mapfiles/ms/icons/orange-dot.png',
      },
      {
        id: 10,
        textColorClass: 'indigo--text text-darken-4',
        label: 'Zafiros',
        border: '#1A237E',
        background: 'rgba(26, 35, 126, 0.4)',
        marker: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
      },
      {
        id: 11,
        textColorClass: 'blue-grey--text text-darken-3',
        label: 'Regulares',
        border: '#37474F',
        background: 'rgba(55, 71, 79, 0.4)',
        marker: 'http://maps.google.com/mapfiles/ms/icons/ltblue-dot.png',
      },
      {
        id: 12,
        textColorClass: 'light-green--text text--accent-3',
        label: 'Ajuste',
        border: '#76FF03',
        background: 'rgba(118, 255, 3, 0.4)',
        marker: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
      },
      {
        id: 14,
        textColorClass: 'purple--text text--accent-3',
        label: 'Correteo',
        border: '#D500F9',
        background: 'rgba(213, 0, 249, 0.4)',
        marker: 'http://maps.google.com/mapfiles/ms/icons/pink-dot.png',
      },
      {
        id: 15,
        textColorClass: 'purple--text text--accent-3',
        label: 'Prospecto de reingreso',
        border: '#FF5252',
        background: 'rgba(255, 82, 82, 0.4)',
        marker: 'http://maps.google.com/mapfiles/ms/icons/pink-dot.png',
      },
    ],
  },
  mutations: {},
  actions: {
    fnGetBorder() {
      return '#EEFF41';
    },
  },
  modules: {},
  plugins: [...servicePlugins, auth],
});

export default store;
