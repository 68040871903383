<template>
  <v-container fluid class="">
    <h1>Mapa de proceso principal en Área Psicológica</h1>
    <v-timeline :dense="$vuetify.breakpoint.smAndDown">
      <v-timeline-item color="teal darken-1" fill-dot left>
        <v-card>
          <v-card-title class="teal darken-1">
            <v-icon class="mr-4" dark size="42"> mdi-head-cog-outline </v-icon>
            <h2 class="text-h5 white--text font-weight-light">
              Recepción de requerimiento por profesional
            </h2>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="5"> Profesional: </v-col>
                  <v-col cols="6"
                    ><p>Profesional Franklin Christensen</p></v-col
                  >
                  <v-col cols="5">Fecha de recepción: </v-col>
                  <v-col cols="6"> <p>05/01/2022 14:23</p></v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-card class="mx-auto" max-width="300" tile>
                  <v-list flat>
                    <v-subheader>Requerimientos</v-subheader>
                    <v-list-item-group v-model="selectedItem" color="primary">
                      <v-list-item v-for="(item, i) in items2" :key="i">
                        <v-list-item-icon>
                          <v-icon
                            :title="item.title"
                            :color="item.color"
                            v-text="item.icon"
                          ></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.text"
                          ></v-list-item-title>
                          <v-list-item-subtitle>{{
                            item.observaciones
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="12">
                <p><strong>Observaciones:</strong></p>
                <div>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Asperiores, unde? Voluptatum perferendis illum, ducimus ullam
                  ab incidunt nemo. Iste dicta veniam libero architecto
                  dignissimos labore natus voluptatem quos consectetur, incidunt
                  rem enim recusandae! Suscipit, id.
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn class="" small elevation="" color="primary" text
              >ver mas</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-card slot="opposite">
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">Info general de la persona</h3>
            </div>
          </v-card-title>
          <v-card-text>
            <v-list three-line>
              <template v-for="(item, index) in items_familias">
                <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="item.header"
                ></v-subheader>

                <v-divider
                  v-else-if="item.divider"
                  :key="index"
                  :inset="item.inset"
                ></v-divider>

                <v-list-item v-else :key="item.title">
                  <v-list-item-avatar>
                    <v-img :src="item.avatar"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      class="mb-2"
                      v-html="item.title"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      class="mb-2"
                      v-html="item.subtitle"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="mb-2"
                      v-html="item.telefono"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="mb-2"
                      v-html="item.direccion"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" t>
              <v-icon color="">mdi-plus</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-timeline-item>
      <v-timeline-item color="purple darken-1" fill-dot left>
        <v-card>
          <v-card-title class="purple darken-1">
            <v-icon class="mr-4" dark size="42"> mdi-file </v-icon>
            <h2 class="text-h4 white--text font-weight-light">
              Verficiación de documentación
            </h2>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="6"> Verificado por: </v-col>
                  <v-col cols="6"><strong>Usuario 673</strong></v-col>
                  <v-col cols="6">Fecha de verificación: </v-col>
                  <v-col cols="6"> <strong>19/10/2021 09:23</strong></v-col>
                  <v-col cols="12">
                    <p><strong>Observaciones:</strong></p>
                    <div>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Asperiores, unde? Voluptatum perferendis illum, ducimus
                      ullam ab incidunt nemo. Iste dicta veniam libero
                      architecto dignissimos labore natus voluptatem quos
                      consectetur, incidunt rem enim recusandae! Suscipit, id.
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-card class="mx-auto" max-width="300" tile>
                  <v-list flat>
                    <v-subheader>Documentos verificados</v-subheader>
                    <v-list-item-group v-model="selectedItem" color="primary">
                      <v-list-item v-for="(item, i) in items" :key="i">
                        <v-list-item-icon>
                          <v-icon
                            :title="item.title"
                            :color="item.color"
                            v-text="item.icon"
                          ></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.text"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-timeline-item>
      <v-timeline-item color="cyan lighten-1" fill-dot left>
        <v-card>
          <v-card-title class="cyan lighten-1">
            <v-icon class="mr-4" dark size="42"> mdi-phone </v-icon>
            <h2 class="text-h4 white--text font-weight-light">
              Contacto con usuaria
            </h2>
          </v-card-title>
          <v-card-text class="mt-2">
            <v-img height="100%" src="">
              <v-row align="end" class="fill-height">
                <v-col align-self="start" class="pa-0" cols="12">
                  <v-avatar class="profile ml-2" color="purple" size="120" tile>
                    <v-icon color="" dark size="58">mdi-account</v-icon>
                  </v-avatar>
                </v-col>
                <v-col class="py-0">
                  <v-list-item color="rgba(0, 0, 0, .4)">
                    <v-list-item-content>
                      <v-list-item-title class="text-h6">
                        Gertrude McLaughlin
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Fecha de contacto: 29/04/2022
                        10:45</v-list-item-subtitle
                      >
                      <v-list-item-title class="text-h6">
                        Teléfono
                      </v-list-item-title>
                      <v-list-item-subtitle>4454-8548</v-list-item-subtitle>
                      <v-list-item-title class="text-h6">
                        Dirección
                      </v-list-item-title>
                      <p>1642 Korota Grove</p>
                      <v-list-item-title class="text-h6">
                        Bio
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Voluptas ad sit quisquam saepe ratione, incidunt
                        quos reiciendis mollitia ab at veritatis molestiae quam
                        tempora libero!</v-list-item-subtitle
                      >
                      <v-list-item-title class="text-h6">
                        Otros detalles
                      </v-list-item-title>
                      <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Voluptas ad sit quisquam saepe ratione, incidunt
                        quos reiciendis mollitia ab at veritatis molestiae quam
                        tempora libero!
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-img>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" t> ver más </v-btn>
          </v-card-actions>
        </v-card>
        <v-card slot="opposite" class="" elevation="" max-width="">
          <v-card-title primary-title>
            Caso registrado por entrevistador
          </v-card-title>
          <v-card-text>
            <p>Día:</p>

            <div class="mb-2 text--primary">06/03/2024 a las: 14:45</div>
            <p>Ubicación:</p>
            <div class="text--primary">591 Ciha Circle zona 1</div>
          </v-card-text>
          <v-card-actions>
            <v-btn text color=""> </v-btn>
          </v-card-actions>
        </v-card>
      </v-timeline-item>
      <v-timeline-item color="green lighten-1" fill-dot left>
        <v-card>
          <v-card-title class="green lighten-1">
            <v-icon class="mr-4" dark size="42"> mdi-folder-open </v-icon>
            <h2 class="text-h4 white--text font-weight-">
              Registro de entrevista
            </h2>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-text>
                    <p class="font-weight-bold float-right mb-2">
                      16/06/2022 08:00
                    </p>

                    <p><strong>Detalle del seguimiento:</strong></p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Asperiores, unde? Voluptatum perferendis illum, ducimus
                      ullam ab incidunt nemo. Iste dicta veniam libero
                      architecto dignissimos labore natus voluptatem quos
                      consectetur, incidunt rem enim recusandae! Suscipit, id.
                    </p>
                    <p><strong>Detalle del seguimiento:</strong></p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Similique quia eligendi fugiat tempore explicabo ex velit
                      et perferendis officiis obcaecati omnis esse odit, itaque
                      rem sequi soluta autem in temporibus atque ut id culpa
                      magni nihil eum. Beatae tempora accusamus doloremque...
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn class="" small elevation="" color="primary" text
                      >ver mas</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <div slot="opposite">
          <p>PLAN PSICOTERAPEÚTICO</p>
          <p>
            Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola
            imperdiet nec ut, sed euismod convenire principes at. Est et nobis
            iisque percipit, an vim zril disputando voluptatibus, vix an
            salutandi sententiae.
          </p>
        </div>
      </v-timeline-item>
      <v-timeline-item color="green lighten-1" fill-dot left>
        <v-card>
          <v-card-title class="green lighten-1">
            <v-icon class="mr-4" dark size="42"> mdi-folder-open </v-icon>
            <h2 class="text-h5 white--text font-weight-">
              Registro de entrevista (RECONSULTAS)
            </h2>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-text>
                    <p class="font-weight-bold float-right mb-2">
                      16/06/2022 08:00
                    </p>

                    <p><strong>Detalle del seguimiento:</strong></p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Asperiores, unde? Voluptatum perferendis illum, ducimus
                      ullam ab incidunt nemo. Iste dicta veniam libero
                      architecto dignissimos labore natus voluptatem quos
                      consectetur, incidunt rem enim recusandae! Suscipit, id.
                    </p>
                    <p><strong>Detalle del seguimiento:</strong></p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Similique quia eligendi fugiat tempore explicabo ex velit
                      et perferendis officiis obcaecati omnis esse odit, itaque
                      rem sequi soluta autem in temporibus atque ut id culpa
                      magni nihil eum. Beatae tempora accusamus doloremque...
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn class="" small elevation="" color="primary" text
                      >ver mas</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <div slot="opposite">
          <p>PLAN PSICOTERAPEÚTICO</p>
          <p>
            Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola
            imperdiet nec ut, sed euismod convenire principes at. Est et nobis
            iisque percipit, an vim zril disputando voluptatibus, vix an
            salutandi sententiae.
          </p>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptas
            sit sunt inventore.
          </p>
          <p><strong>Acciones Pertinentes</strong></p>
          <p>
            Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola
            imperdiet nec ut, sed euismod convenire principes at. Est et nobis
            iisque percipit, an vim zril disputando voluptatibus, vix an
            salutandi sententiae.
          </p>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptas
            sit sunt inventore.
          </p>
        </div>
      </v-timeline-item>
      <v-timeline-item color="teal lighten-2" fill-dot right small>
        <v-card>
          <v-card-title class="teal lighten-2 justify-end">
            <h2 class="text-h5 mr-4 white--text font-weight-light">
              Fin de acompañamiento
            </h2>
            <v-icon dark size="42"> mdi-head-cog-outline </v-icon>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col class="hidden-sm-and-down" md="2">
                <v-icon size="64"> mdi-head-cog-outline </v-icon>
              </v-col>
              <v-col cols="12" md="10">
                <span class="font-weight-medium">Motivos:</span>
                <div>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo,
                  ex ullam?
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Possimus quis ipsam est nam. Incidunt explicabo, sit est
                    consequatur esse pariatur optio aliquam similique, quod nam,
                    ut fuga odio rem? Voluptatum amet perferendis iste,
                    provident asperiores enim doloribus sequi. Pariatur saepe
                    enim nostrum! Suscipit, pariatur repellendus!
                  </p>
                </div>
                <span class="font-weight-medium">Detalle:</span>
                <div>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Assumenda, excepturi culpa animi esse ipsum provident?
                </div>
                <span class="font-weight-medium mt-3"
                  ><strong>Observaciones</strong>:</span
                >
                <div>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Voluptatum dolor officia dolore. Esse voluptate debitis
                  voluptas animi? Laudantium dolore eveniet, dicta, dolor vero
                  quasi saepe reprehenderit omnis est aut quibusdam natus
                  nesciunt iusto tempora fuga.
                </div>
              </v-col>
              <v-col>
                <span class="float-right text--disabled">
                  Fecha: 14/07/2023 21:26</span
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <p slot="opposite">Otras observaciones</p>
        <p slot="opposite">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
          ratione quibusdam similique. Esse suscipit, quaerat deserunt quod
          quisquam perferendis dolor. Blanditiis vel doloribus alias nihil
          maxime molestias ad magnam eveniet numquam aut. Laborum nostrum
          officia possimus obcaecati nobis earum nihil error, vero quas, illo
          culpa.
        </p>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      selectedItem: 1,
      items: [
        {
          title: '',
          color: 'green',
          text: 'Titulo de documento 001',
          icon: 'mdi-check',
        },
        {
          title: 'No presento este documento',
          color: 'green',
          text: 'Titulo de documento 023',
          icon: 'mdi-check',
        },
        {
          title: '',
          color: 'green',
          text: 'Titulo de documento 123',
          icon: 'mdi-check',
        },
        {
          title: '',
          color: 'green',
          text: 'Titulo de documento 304',
          icon: 'mdi-check',
        },
      ],
      items2: [
        {
          title: '',
          color: 'green',
          text: 'Título de requerimiento 001',
          observaciones:
            'Loreectetur adipisicing elit. Cumque es m, ipsum dolor sit amet consectetur adipisicing elit. Cumque esse voluptas placeat!',
          icon: 'mdi-check',
        },
        {
          title: 'No presento este documento',
          color: 'green',
          text: 'Título de requerimiento 023',
          observaciones:
            'Lorem, ipsum dolor sit amet consectetur adipisicing  sit amet consectetur adipisicing elit. Cumque esse voluptas placeat!',
          icon: 'mdi-check',
        },
        {
          title: '',
          color: 'green',
          text: 'Título de requerimiento 123',
          observaciones:
            'Lorem, ipsum d sit amet consectetur adipisicingolor sit amet consectetur adipisicing elit. Cumque esse voluptas placeat!',
          icon: 'mdi-check',
        },
      ],
      items_familias: [
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
          title: 'Gertrude McLaughlin',
          subtitle: `<span class="text--primary">Padre</span> Descripción/Observaciones`,
          telefono: `<span class="text--primary">Teléfono</span> 55544849`,
          direccion: `<span class="text--primary">Dirección</span> 847 Naaw Trail`,
        },
        { divider: true, inset: true },
      ],
    };
  },
};
</script>
