<template>
  <v-app>
    <!-- notificaciones  -->
    <notifications group="foo" />
    <!-- notificaciones  -->
    <!-- global loading screen  -->
    <v-overlay z-index="9999" :value="globalLoading">
      <span>cargando</span>
      <v-progress-linear
        indeterminate
        color="primary darken-2"
      ></v-progress-linear>
    </v-overlay>
    <!-- global loading screen  -->

    <mainmenuappbar v-if="flagLoggedIn" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import mainmenuappbar from './components/mainmenuappbar.vue';

export default {
  name: 'app',
  components: {
    mainmenuappbar,
  },
  data: () => ({
    overlay: false,
    //
  }),
  computed: {
    flagLoggedIn() {
      return this.$store.state.auth.accessToken ? true : false;
    },
    globalLoading() {
      return this.$store.state.globalLoading;
    },
  },
  methods: {},
};
</script>

<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 15px !important;
}
.v-btn {
  /* border: 1px solid white; */
  border-radius: 15;
}
#loadingScreen {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100vh;
  background-color: rgba(45, 55, 185, 0.151);
}
</style>
