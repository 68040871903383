import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import fundacion_juridica_familia from '../views/fundacion_juridica_familia.vue';
import fundacion_procuracion_penal from '../views/fundacion_procuracion_penal.vue';
import fundacion_juridica_penal from '../views/fundacion_juridica_penal.vue';
import fundacion_procuracion_familia from '../views/fundacion_procuracion_familia.vue';
import fundacion_area_psicologica from '../views/fundacion_area_psicologica.vue';
import fundacion_trabajo_social from '../views/fundacion_trabajo_social.vue';
import fundacion_atencion_primaria from '../views/fundacion_atencion_primaria.vue';
import conta_stef from '../views/conta_stef.vue';
import store from './../store';
/** */
// this.$store.state.auth.payload.usuario.codigo;
async function isLoggedIn(to, from, next) {
  store;
  store.state.globalLoading = true;

  try {
    const logged = await store.dispatch('auth/authenticate');

    if (logged) {
      console.log(logged.usuario.sesion_rol);
      //bypass superusers
      if (logged.usuario.sesion_rol == 0) {
        store.state.globalLoading = false;
        next();
      } else {
        //búscar sí la ruta a la que nos dirigimos esta permitida
        let canWeGo = logged.modulos.findIndex(
          (allowed_modulos) => allowed_modulos.url == to.path.toString()
        );
        //flag global loading
        store.state.globalLoading = false;

        //unrestricted views
        let isVisita = false;
        if (to.path.substring(0, 7) == '/visita') {
          isVisita = true;
        }

        if (to.path != '/') {
          //permitir ir a visita detalle o a cualquier otra ruta permitida
          if (canWeGo != -1 || isVisita == true) {
            next();
          } else {
            console.log('Acceso denegado ' + canWeGo);
            next('/');
          }
        } else {
          next();
        }
      }
    }
  } catch (e) {
    if (e.code && e.code === 403) {
      next('/');
      store.state.globalLoading = false;
    } else {
      store.state.globalLoading = false;
      next('/login');
    }
  } finally {
    store.commit('auth/unsetAuthenticatePending');
  }
}
/** */
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/visita_conta_stef',
    name: 'conta_stef',
    component: conta_stef,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/visita_atencion_primaria',
    name: 'fundacion_atencion_primaria',
    component: fundacion_atencion_primaria,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/visita_juridica_familia',
    name: 'fundacion_juridica_familia',
    component: fundacion_juridica_familia,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/visita_procuracion_penal',
    name: 'fundacion_procuracion_penal',
    component: fundacion_procuracion_penal,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/visita_juridica_penal',
    name: 'fundacion_juridica_penal',
    component: fundacion_juridica_penal,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/visita_procuracion_familia',
    name: 'fundacion_procuracion_familia',
    component: fundacion_procuracion_familia,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/visita_area_psicologica',
    name: 'fundacion_area_psicologica',
    component: fundacion_area_psicologica,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/visita_trabajo_social',
    name: 'fundacion_trabajo_social',
    component: fundacion_trabajo_social,
    beforeEnter: isLoggedIn,
  },
  {
    path: '/mapa',
    name: 'Mapa de visitas',
    component: () =>
      import(/* webpackChunkName: "visitasmapa" */ '../views/visitasMapa.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/detalle',
    name: 'Visitas',
    component: () =>
      import(
        /* webpackChunkName: "visitasdetalle" */ '../views/visitasDetalle.vue'
      ),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/visita/:id',
    name: 'Detalle de visita',
    component: () =>
      import(
        /* webpackChunkName: "visitadetalle" */ '../views/visitaDetalle.vue'
      ),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/usuarios_administrativos',
    name: 'Gestión de usuarios',
    component: () =>
      import(
        /* webpackChunkName: "usuariosgestion" */ '../views/usuariosGestion.vue'
      ),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/usuarios_supervisoras',
    name: 'Gestión credenciales de supervisoras',
    component: () =>
      import(
        /* webpackChunkName: "supervisoras_tabla_configuraciones" */ '../views/supervisoras_tabla_configuraciones.vue'
      ),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/visitacrear',
    name: '(DEVTEST) Crear visita',
    component: () =>
      import(/* webpackChunkName: "visitacrear" */ '../views/visitaCrear.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/visitacrearinitlab',
    name: '(DEVTEST) Crear registro inicio de labores',
    component: () =>
      import(
        /* webpackChunkName: "iniciodelaborescrear" */ '../views/iniciolaborescrear.vue'
      ),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/inicio_de_labores',
    name: 'Registro de inicio de labores',
    component: () =>
      import(/* webpackChunkName: "initlab" */ '../views/iniciolabores.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/prospectos',
    name: 'Prospectos',
    component: () =>
      import(
        /* webpackChunkName: "PROSPECTOS" */ '../views/visitas/prospectos.vue'
      ),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/nombramientos',
    name: 'Nombramientos',
    component: () =>
      import(
        /* webpackChunkName: "NOMBRAMIENTOS" */ '../views/visitas/nombramientos.vue'
      ),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/reunion-consejeras',
    name: 'Reuniones de consejeras',
    component: () =>
      import(
        /* webpackChunkName: "REUIONCONSEJERAS" */ '../views/visitas/reunion_consejeras.vue'
      ),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/login',
    name: 'inicio de sesión',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/login.vue'),
  },
  {
    path: '/reportes',
    name: 'Resumen de visitas',
    component: () =>
      import(
        /* webpackChunkName: "reporteria" */ '../views/reporteria/reporteria.vue'
      ),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/actividades',
    name: 'Resumen de actividades',
    component: () =>
      import(
        /* webpackChunkName: "actividades" */ '../views/visitas/actividades.vue'
      ),
    beforeEnter: isLoggedIn,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
