<template>
  <v-container fluid class="">
    <h1>Mapa de proceso principal en Área Trabajo Social</h1>
    <v-timeline :dense="$vuetify.breakpoint.smAndDown">
      <v-timeline-item color="teal darken-1" fill-dot left>
        <v-card>
          <v-card-title class="teal darken-1">
            <v-icon class="mr-4" dark size="42">
              mdi-account-supervisor-circle-outline
            </v-icon>
            <h2 class="text-h5 white--text font-weight-light">
              Recepción de requerimiento
            </h2>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="5">Fecha de recepción: </v-col>
                  <v-col cols="6"> <p>05/01/2022 14:23</p></v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-card class="mx-auto" max-width="300" tile>
                  <v-list flat>
                    <v-subheader>Solicitudes / Requerimiento</v-subheader>
                    <v-list-item-group v-model="selectedItem" color="primary">
                      <v-list-item v-for="(item, i) in items2" :key="i">
                        <v-list-item-icon>
                          <v-icon
                            :title="item.title"
                            :color="item.color"
                            v-text="item.icon"
                          ></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.text"
                          ></v-list-item-title>
                          <v-list-item-subtitle>{{
                            item.observaciones
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="12">
                <p><strong>Observaciones:</strong></p>
                <div>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Asperiores, unde? Voluptatum perferendis illum, ducimus ullam
                  ab incidunt nemo. Iste dicta veniam libero architecto
                  dignissimos labore natus voluptatem quos consectetur, incidunt
                  rem enim recusandae! Suscipit, id.
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn class="" small elevation="" color="primary" text
              >ver mas</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-card slot="opposite">
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">Info. general persona</h3>
            </div>
          </v-card-title>
          <v-card-text>
            <v-list three-line>
              <template v-for="(item, index) in items_familias">
                <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="item.header"
                ></v-subheader>

                <v-divider
                  v-else-if="item.divider"
                  :key="index"
                  :inset="item.inset"
                ></v-divider>

                <v-list-item v-else :key="item.title">
                  <v-list-item-avatar>
                    <v-img :src="item.avatar"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      class="mb-2"
                      v-html="item.title"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      class="mb-2"
                      v-html="item.subtitle"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="mb-2"
                      v-html="item.telefono"
                    ></v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="mb-2"
                      v-html="item.direccion"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" t>
              <v-icon color="">mdi-plus</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-timeline-item>
      <v-timeline-item color="green lighten-1" fill-dot right>
        <v-card>
          <v-card-title class="green lighten-1">
            <v-icon class="mr-4" dark size="42"> mdi-folder-open </v-icon>
            <h2 class="text-h4 white--text font-weight-">
              Perfilación tipo de intervención
            </h2>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-text>
                    <p class="font-weight-bold float-right mb-2">
                      22/05/2021 08:00
                    </p>

                    <p><strong>Detalle de la intervención :</strong></p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Asperiores, unde? Voluptatum perferendis illum, ducimus
                      ullam ab incidunt nemo. Iste dicta veniam libero
                      architecto dignissimos labore natus voluptatem quos
                      consectetur, incidunt rem enim recusandae! Suscipit, id.
                    </p>
                    <p><strong>Detalle del seguimiento:</strong></p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Similique quia eligendi fugiat tempore explicabo ex velit
                      et perferendis officiis obcaecati omnis esse odit, itaque
                      rem sequi soluta autem in temporibus atque ut id culpa
                      magni nihil eum. Beatae tempora accusamus doloremque...
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn class="" small elevation="" color="primary" text
                      >ver mas</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <div slot="opposite">
          <p>Observaciones</p>
          <p>
            Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola
            imperdiet nec ut, sed euismod convenire principes at. Est et nobis
            iisque percipit, an vim zril disputando voluptatibus, vix an
            salutandi sententiae.
          </p>
        </div>
      </v-timeline-item>
      <v-timeline-item color="grey darken-1" fill-dot left>
        <v-card>
          <v-card-title class="grey darken-1">
            <v-icon class="mr-4" dark size="42"> mdi-lifebuoy </v-icon>
            <h2 class="text-h4 white--text font-weight-light">Intervención</h2>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="6"> Verificado por: </v-col>
                  <v-col cols="6"><strong>Usuario 673</strong></v-col>
                  <v-col cols="6">Fecha de verificación: </v-col>
                  <v-col cols="6"> <strong>19/10/2021 09:23</strong></v-col>
                  <v-col cols="12">
                    <p><strong>Observaciones:</strong></p>
                    <div>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Asperiores, unde? Voluptatum perferendis illum, ducimus
                      ullam ab incidunt nemo. Iste dicta veniam libero
                      architecto dignissimos labore natus voluptatem quos
                      consectetur, incidunt rem enim recusandae! Suscipit, id.
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-card class="mx-auto" max-width="300" tile>
                  <v-list flat>
                    <v-subheader>Aréa de intervención</v-subheader>
                    <v-list-item-group v-model="selectedItem" color="primary">
                      <v-list-item v-for="(item, i) in items" :key="i">
                        <v-list-item-icon>
                          <v-icon
                            :title="item.title"
                            :color="item.color"
                            v-text="item.icon"
                          ></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.text"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card slot="opposite">
          <v-card-title class="green lighten-1">
            <v-icon class="mr-4" dark size="42"> mdi-folder-open </v-icon>
            <h2 class="text-h4 white--text font-weight-">
              Seguimiento del caso
            </h2>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-text>
                    <p class="font-weight-bold float-right mb-2">
                      16/02/2022 08:00
                    </p>

                    <p><strong>Detalle del seguimiento:</strong></p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Asperiores, unde? Voluptatum perferendis illum, ducimus
                      ullam ab incidunt nemo. Iste dicta veniam libero
                      architecto dignissimos labore natus voluptatem quos
                      consectetur, incidunt rem enim recusandae! Suscipit, id.
                    </p>
                    <p><strong>Detalle del seguimiento:</strong></p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Similique quia eligendi fugiat tempore explicabo ex velit
                      et perferendis officiis obcaecati omnis esse odit, itaque
                      rem sequi soluta autem in temporibus atque ut id culpa
                      magni nihil eum. Beatae tempora accusamus doloremque...
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn class="" small elevation="" color="primary" text
                      >ver mas</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card slot="opposite">
          <v-card-title class="green lighten-1">
            <v-icon class="mr-4" dark size="42"> mdi-folder-open </v-icon>
            <h2 class="text-h4 white--text font-weight-">
              Seguimiento del caso
            </h2>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-text>
                    <p class="font-weight-bold float-right mb-2">
                      13/04/2022 08:00
                    </p>

                    <p><strong>Detalle del seguimiento:</strong></p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Asperiores, unde? Voluptatum perferendis illum, ducimus
                      ullam ab incidunt nemo. Iste dicta veniam libero
                      architecto dignissimos labore natus voluptatem quos
                      consectetur, incidunt rem enim recusandae! Suscipit, id.
                    </p>
                    <p><strong>Detalle del seguimiento:</strong></p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Similique quia eligendi fugiat tempore explicabo ex velit
                      et perferendis officiis obcaecati omnis esse odit, itaque
                      rem sequi soluta autem in temporibus atque ut id culpa
                      magni nihil eum. Beatae tempora accusamus doloremque...
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn class="" small elevation="" color="primary" text
                      >ver mas</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-timeline-item>
      <v-timeline-item color="primary" fill-dot right small>
        <p>Cierre del caso: <strong>30/05/2022 22:15</strong></p>
        <p>Cierre de intervención: <strong>02/12/2021 08:15</strong></p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Atque
          accusamus debitis fugit corrupti, laboriosam harum odit esse
          consequatur.
        </p>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      selectedItem: 1,
      items: [
        {
          title: '',
          color: 'red',
          text: 'Apoyo humanitario',
          icon: 'mdi-bottle-tonic-plus',
        },
        {
          title: '',
          color: 'primary',
          text: 'Atención casa refugio',
          icon: 'mdi-home',
        },
        {
          title: '',
          color: 'purple',
          text: 'Estudio S. Económico',
          icon: 'mdi-home-group',
        },
        {
          title: '',
          color: 'brown',
          text: 'Visita domiciliaria',
          icon: 'mdi-home-map-marker',
        },
      ],
      items2: [
        {
          title: '',
          color: 'green',
          text: 'Requerimiento 001',
          icon: 'mdi-check',
        },
        {
          title: 'No presento este documento',
          color: 'green',
          text: 'Requerimiento 023',
          icon: 'mdi-check',
        },
        {
          title: '',
          color: 'green',
          text: 'Requerimiento 123',
          icon: 'mdi-check',
        },
      ],
      items_familias: [
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: 'Phillip Peralta',
          subtitle: `<span class="text--primary">Padre</span> Descripción/Observaciones`,
          telefono: `<span class="text--primary">Teléfono</span> 55544849`,
          direccion: `<span class="text--primary">Dirección</span> 847 Naaw Trail`,
        },
      ],
    };
  },
};
</script>
