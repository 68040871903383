// src/store/services/users.js
import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '../../feathers-client';

class ReporteResumenConsejerasPorRegion extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'ReporteResumenConsejerasPorRegion';
  // Define default properties here
  static instanceDefaults() {
    return {};
  }
}
const servicePath = 'reporte-resumen-consejeras-por-region';
const servicePlugin = makeServicePlugin({
  namespaced: true,
  namespace: 'ReporteResumenConsejerasPorRegion',
  Model: ReporteResumenConsejerasPorRegion,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
