<template>
  <v-container fluid class="">
    <h1>Mapa de proceso principal en Área Jurídica de Familia</h1>
    <v-timeline :dense="$vuetify.breakpoint.smAndDown">
      <v-timeline-item color="cyan lighten-1" fill-dot left>
        <v-card>
          <v-card-title class="cyan lighten-1">
            <v-icon class="mr-4" dark size="42"> mdi-file-outline </v-icon>
            <h2 class="text-h4 white--text font-weight-light">
              Verficiación de documentación
            </h2>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="6"> Verificado por: </v-col>
                  <v-col cols="6"><strong>Usuario 673</strong></v-col>
                  <v-col cols="6">Fecha de verificación: </v-col>
                  <v-col cols="6"> <strong>19/10/2021 09:23</strong></v-col>
                  <v-col cols="12">
                    <p><strong>Observaciones:</strong></p>
                    <div>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Asperiores, unde? Voluptatum perferendis illum, ducimus
                      ullam ab incidunt nemo. Iste dicta veniam libero
                      architecto dignissimos labore natus voluptatem quos
                      consectetur, incidunt rem enim recusandae! Suscipit, id.
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-card class="mx-auto" max-width="300" tile>
                  <v-list flat>
                    <v-subheader>Documentos verificados</v-subheader>
                    <v-list-item-group v-model="selectedItem" color="primary">
                      <v-list-item v-for="(item, i) in items" :key="i">
                        <v-list-item-icon>
                          <v-icon
                            :title="item.title"
                            :color="item.color"
                            v-text="item.icon"
                          ></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.text"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card slot="opposite">
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">Detalles de la familia</h3>
            </div>
          </v-card-title>
          <v-card-text>
            <v-list three-line>
              <template v-for="(item, index) in items_familias">
                <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="item.header"
                ></v-subheader>

                <v-divider
                  v-else-if="item.divider"
                  :key="index"
                  :inset="item.inset"
                ></v-divider>

                <v-list-item v-else :key="item.title">
                  <v-list-item-avatar>
                    <v-img :src="item.avatar"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-html="item.title"></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="item.subtitle"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" t>
              <v-icon color="">mdi-plus</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-timeline-item>

      <v-timeline-item color="cyan lighten-1" fill-dot left>
        <v-card>
          <v-card-title class="cyan lighten-1">
            <v-icon class="mr-4" dark size="42"> mdi-file-outline </v-icon>
            <h2 class="text-h4 white--text font-weight-light">
              Verficiación de documentación
            </h2>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="5"> Verificado por: </v-col>
                  <v-col cols="6"
                    ><span class="font-weight-light">Usuario 126</span></v-col
                  >
                  <v-col cols="5">Fecha de verificación: </v-col>
                  <v-col cols="6"
                    ><span class="font-weight-light"
                      >20/11/2021 16:50</span
                    ></v-col
                  >
                  <v-col cols="12">
                    <p><strong>Observaciones:</strong></p>
                    <div>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Asperiores, unde? Voluptatum perferendis illum, ducimus
                      ullam ab incidunt nemo. Iste dicta veniam libero
                      architecto dignissimos labore natus voluptatem quos
                      consectetur, incidunt rem enim recusandae! Suscipit, id.
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-card class="mx-auto" max-width="300" tile>
                  <v-list flat>
                    <v-subheader>Documentos verificados</v-subheader>
                    <v-list-item-group v-model="selectedItem" color="primary">
                      <v-list-item v-for="(item, i) in items2" :key="i">
                        <v-list-item-icon>
                          <v-icon
                            :title="item.title"
                            :color="item.color"
                            v-text="item.icon"
                          ></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.text"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-timeline-item>

      <v-timeline-item color="red lighten-1" fill-dot left small>
        <v-card>
          <v-card-title class="red lighten-1 justify-end">
            <h2 class="text-h5 mr-4 white--text font-weight-light">
              Registro de entrevistador - Área familiar
            </h2>
            <v-icon dark size="42"> mdi-account-multiple-outline </v-icon>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col class="hidden-sm-and-down" md="2">
                <v-icon size="64"> mdi-server-network </v-icon>
              </v-col>
              <v-col cols="12" md="10">
                <span class="font-weight-medium">Area:</span>
                <div>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo,
                  ex ullam?
                </div>
                <span class="font-weight-medium">Detalle:</span>
                <div>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Assumenda, excepturi culpa animi esse ipsum provident?
                </div>
                <span class="font-weight-medium mt-3"
                  ><strong>Observaciones</strong>:</span
                >
                <div>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Voluptatum dolor officia dolore. Esse voluptate debitis
                  voluptas animi? Laudantium dolore eveniet, dicta, dolor vero
                  quasi saepe reprehenderit omnis est aut quibusdam natus
                  nesciunt iusto tempora fuga.
                </div>
              </v-col>
              <v-col>
                <span class="float-right text--disabled">
                  Fecha: 20/11/2021 17:47</span
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-timeline-item>

      <v-timeline-item color="green lighten-1" fill-dot left>
        <v-card>
          <v-card-title class="green lighten-1">
            <v-icon class="mr-4" dark size="42"> mdi-folder-open </v-icon>
            <h2 class="text-h4 white--text font-weight-">
              Seguimiento del caso
            </h2>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-text>
                    <p class="font-weight-bold float-right mb-2">
                      22/11/2021 08:25
                    </p>

                    <p><strong>Detalle del seguimiento:</strong></p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Asperiores, unde? Voluptatum perferendis illum, ducimus
                      ullam ab incidunt nemo. Iste dicta veniam libero
                      architecto dignissimos labore natus voluptatem quos
                      consectetur, incidunt rem enim recusandae! Suscipit, id.
                    </p>
                    <p><strong>Detalle del seguimiento:</strong></p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Similique quia eligendi fugiat tempore explicabo ex velit
                      et perferendis officiis obcaecati omnis esse odit, itaque
                      rem sequi soluta autem in temporibus atque ut id culpa
                      magni nihil eum. Beatae tempora accusamus doloremque...
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn class="" small elevation="" color="primary" text
                      >ver mas</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <div slot="opposite">
          <p>Acciones pertinentes</p>
          <p>
            Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola
            imperdiet nec ut, sed euismod convenire principes at. Est et nobis
            iisque percipit, an vim zril disputando voluptatibus, vix an
            salutandi sententiae.
          </p>
        </div>
      </v-timeline-item>
      <v-timeline-item color="green lighten-1" fill-dot left>
        <v-card>
          <v-card-title class="green lighten-1">
            <v-icon class="mr-4" dark size="42"> mdi-folder-open </v-icon>
            <h2 class="text-h4 white--text font-weight-">
              Seguimiento del caso
            </h2>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-text>
                    <p class="font-weight-bold float-right mb-2">
                      28/11/2021 09:25
                    </p>

                    <p><strong>Detalle del seguimiento:</strong></p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Asperiores, unde? Voluptatum perferendis illum, ducimus
                      ullam ab incidunt nemo. Iste dicta veniam libero
                      architecto dignissimos labore natus voluptatem quos
                      consectetur, incidunt rem enim recusandae! Suscipit, id.
                    </p>
                    <p><strong>Tema del día</strong></p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      Similique quia eligendi fugiat tempore explicabo ex velit
                      et perferendis officiis obcaecati omnis esse odit, itaque
                      rem sequi soluta autem in temporibus atque ut id culpa
                      magni nihil eum. Beatae tempora accusamus doloremque...
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn class="" small elevation="" color="primary" text
                      >ver mas</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <div slot="opposite">
          <p>Acciones pertinentes</p>
          <v-list subheader three-line>
            <v-subheader
              >Detalles de las acciones, observaciones de las acciones,
              comentarios adicionales.</v-subheader
            >

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Accion 1</v-list-item-title>
                <v-list-item-subtitle
                  >Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Iste facere fugiat impedit veniam corrupti tempora!
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Acción 2</v-list-item-title>
                <v-list-item-subtitle
                  >Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus esse veritatis sint!
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-timeline-item>
      <!-- /***/  SENTENCIA */ -->
      <v-timeline-item color="green lighten-2" fill-dot right small>
        <v-card>
          <v-card-title class="green lighten-2 justify-end">
            <h2 class="text-h5 mr-4 white--text font-weight-light">
              Sentencia o auto / Convenio
            </h2>
            <v-icon dark size="42"> mdi-gavel </v-icon>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col class="hidden-sm-and-down" md="2">
                <v-icon size="64"> mdi-gavel </v-icon>
              </v-col>
              <v-col cols="12" md="10">
                <span class="font-weight-medium">Detalle:</span>
                <div>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo,
                  ex ullam?
                </div>
                <span class="font-weight-medium">Detalle:</span>
                <div>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Assumenda, excepturi culpa animi esse ipsum provident?
                </div>
                <span class="font-weight-medium mt-3"
                  ><strong>Observaciones</strong>:</span
                >
                <div>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Voluptatum dolor officia dolore. Esse voluptate debitis
                  voluptas animi? Laudantium dolore eveniet, dicta, dolor vero
                  quasi saepe reprehenderit omnis est aut quibusdam natus
                  nesciunt iusto tempora fuga.
                </div>
              </v-col>
              <v-col>
                <span class="float-right text--disabled">
                  Fecha: 30/11/2021 21:26</span
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-timeline-item>

      <v-timeline-item color="primary" fill-dot right small>
        <p>Cierre del caso: <strong>30/11/2021 22:15</strong></p>
        <p>
          Devolución expediente a usuaria: <strong>02/12/2021 08:15</strong>
        </p>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      selectedItem: 1,
      items: [
        {
          title: '',
          color: 'red',
          text: 'Titulo de documento 001',
          icon: 'mdi-flag',
        },
        {
          title: 'No presento este documento',
          color: 'red',
          text: 'Titulo de documento 023',
          icon: 'mdi-flag',
        },
        {
          title: '',
          color: 'green',
          text: 'Titulo de documento 123',
          icon: 'mdi-check',
        },
      ],
      items2: [
        {
          title: '',
          color: 'green',
          text: 'Titulo de documento 001',
          icon: 'mdi-check',
        },
        {
          title: 'No presento este documento',
          color: 'green',
          text: 'Titulo de documento 023',
          icon: 'mdi-check',
        },
        {
          title: '',
          color: 'green',
          text: 'Titulo de documento 123',
          icon: 'mdi-check',
        },
      ],
      items_familias: [
        { header: 'Familia Peralta Payne' },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          title: 'Luis Peralta',
          subtitle: `<span class="text--primary">Padre</span> Descripción/Observaciones`,
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: 'Phillip Peralta',
          subtitle: `<span class="text--primary">Hijo</span> Descripción/Observaciones`,
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          title: 'Elsie Peralta',
          subtitle:
            '<span class="text--primary">Hija</span> Descripción/Observaciones',
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
          title: 'Marian Payne',
          subtitle:
            '<span class="text--primary">Madre</span> Descripción/Observaciones',
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
          title: 'Jon Payne',
          subtitle:
            '<span class="text--primary">Tía</span> Descripción/Observaciones',
        },
      ],
    };
  },
};
</script>
