<template>
  <v-app-bar app :color="'primary'" dark>
    <div class="d-flex justify-center">
      <v-img
        alt="Vuetify Name"
        class="shrink mt-1 ml-4 hidden-sm-and-down"
        contain
        min-width="220"
        :src="require('@/assets/images/logoLeCleire.png')"
        width="220"
      />
    </div>
    <v-spacer></v-spacer>
    <span v-if="usuario.sesion_rol == 2" class="mt-2"
      >{{ usuario.division }} - {{ usuario.region }} - {{ usuario.tipo }}</span
    >
    <v-spacer></v-spacer>

    <v-btn v-if="flagLoggedIn" href="/" text>
      <span class="mr-2">Menu</span>
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>
    <!-- modulos parametrizables -->

    <v-btn
      v-for="item in items"
      :key="item.id"
      :href="item.url"
      text
      :title="item.titulo"
    >
      <v-icon>{{ item.icono }}</v-icon>
    </v-btn>

    <!-- fin modulos parametrizables -->

    <v-btn @click="logout" v-if="flagLoggedIn" text title="Cerrar sesión">
      <span class="mr-2"></span>
      <v-icon class="mr-1">mdi-power-standby</v-icon>
      {{ usuario.alias }}
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'mainmenuappbar',
  data() {
    return {
      items: [],
    };
  },
  computed: {
    globalLoading() {
      return this.$store.state.globalLoading;
    },
    flagLoggedIn() {
      return this.$store.state.auth.accessToken ? true : false;
    },

    usuario() {
      return this.$store.state.auth.payload.usuario;
    },
  },
  methods: {
    async logout() {
      this.$store.state.globalLoading = true;
      await this.$store.dispatch('auth/logout').then(() => {
        this.$router.go();
        this.$store.state.globalLoading = false;
      });
    },
    ...mapActions('Modulos', {
      findModulos: 'find',
    }),
    getParams() {
      const params = {};

      if (
        this.$store.state.auth.payload &&
        this.$store.state.auth.payload.usuario.sesion_rol != 0
      ) {
        //
        let allowed_modulos_id = this.$store.state.auth.payload.modulos.map(
          function (a) {
            return a.id;
          }
        );
        //
        params['id'] = {
          $in: allowed_modulos_id,
        };
      }

      params['$sort'] = { posicion: 1 };
      return params;
    },
  },
  watch: {
    globalLoading(newValue) {
      if (newValue == false) {
        this.findModulos({ query: this.getParams() }).then((result) => {
          this.items = result.data;
        });
      }
    },
  },
  mounted() {},
};
</script>
