<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col v-for="(item, i) in items" :key="i" cols="4">
        <v-card :color="color" class="card-outter" height="100%">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="" v-text="item.titulo"></v-card-title>

              <v-card-text class="text-left">
                {{ item.descripcion }}
                <strong v-if="item.titulo == 'Coordendas diarias'">{{
                  hoy
                }}</strong>
              </v-card-text>

              <v-card-actions class="card-actions">
                <v-btn
                  class="ml-2 mt-5"
                  outlined
                  rounded
                  small
                  :href="item.url"
                >
                  {{ item.boton }}
                </v-btn>
              </v-card-actions>
            </div>

            <v-avatar class="ma-3" size="80" tile>
              <v-icon> {{ item.icono }}</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
      <!-- <v-col>
        <GmapMap
          :center="{ lat: 10, lng: 10 }"
          :zoom="7"
          map-type-id="terrain"
          style="width: 500px; height: 300px"
        >
          <GmapMarker
            :key="index"
            :position="{ lat: 1.38, lon: 103.8 }"
            :clickable="true"
            :draggable="true"
            @click="center = { lat: 1.38, lon: 103.8 }"
          />
        </GmapMap>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es');
export default {
  name: 'mainmenu',

  data: () => ({
    hoy: moment().format('DD/MMMM/YYYY'),
    color: '#FFF',
    items: [],
  }),
  methods: {
    ...mapActions('Modulos', {
      findModulos: 'find',
    }),
    getParams() {
      const params = {};

      if (this.$store.state.auth.payload.usuario.sesion_rol != 0) {
        //
        let allowed_modulos_id = this.$store.state.auth.payload.modulos.map(
          function (a) {
            return a.id;
          }
        );
        //
        params['id'] = {
          $in: allowed_modulos_id,
        };
      }

      params['$sort'] = { posicion: 1 };
      return params;
    },
  },
  mounted() {
    this.findModulos({ query: this.getParams() }).then((result) => {
      this.items = result.data;
    });
  },
};
</script>

<style>
.card-outter {
  position: relative;
  padding-bottom: 50px;
}
.card-actions {
  position: absolute;
  bottom: 0;
}
</style>
