<template>
  <v-container class="">
    <v-row>
      <v-col
        ><input
          type="file"
          class="my_input"
          id="upload"
          @change="cargarPedidosExcel"
        />

        <div ref="result"></div>
      </v-col>
      <v-col>
        <v-data-table
          dense
          :headers="headers"
          :items="arrayErpPedidosGuatex"
          item-key="name"
          class="elevation-1"
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import xlsx from 'xlsx';
export default {
  name: 'conta_stef',
  data() {
    return {
      arrayErpPedidosGuatex: [],
      //

      // prettier-ignore
      headers: [
        
        
        { text: 'conta_FACTURA', value: 'conta_FACTURA' },
        { text: 'conta_FORMULARIO', value: 'conta_FORMULARIO' },
        { text: 'conta_MONTO', value: 'conta_MONTO' },
        

        { text: 'comparativo_FACTURA', align: 'start', sortable: false, value: 'comparativo_FACTURA'},
        { text: 'comparativo_FORMULARIO', value: 'comparativo_FORMULARIO' },
        { text: 'comparativo_MONTO', value: 'comparativo_MONTO' },


        { text: 'reten_emitidas_FC', value: 'reten_emitidas_FC' },
        { text: 'reten_emitidas_No', value: 'reten_emitidas_No' },
        { text: 'reten_emitidas_TOTAL', value: 'reten_emitidas_TOTAL' },
        { text: 'reten_emitidas_mes', value: 'reten_emitidas_mes' },
        
        { text: 'reten_no_registradas_FC', value: 'reten_no_registradas_FC' },
        { text: 'reten_no_registradas_MES_RETENCION_EMITIDA', value: 'reten_no_registradas_MES_RETENCION_EMITIDA' },
        { text: 'reten_no_registradas_No', value: 'reten_no_registradas_No' },
        { text: 'reten_no_registradas_TOTAL', value: 'reten_no_registradas_TOTAL' },

      ],
    };
  },
  methods: {
    cargarPedidosExcel(e) {
      const files = e.target.files;

      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        return alert(
          "La extención del archivo es incorrecta. Formatos aceptados: 'xls' or 'xlsx'."
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const excelContent = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(excelContent, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            raw: false,
          }); // Generate JSON table content，wb.Sheets[Sheet名]    Get the excelContent of the first sheet
          const excellist = []; // Clear received excelContent
          // Edit excelContent
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          }

          this.arrayErpPedidosGuatex = excellist;
          console.log(this.arrayErpPedidosGuatex);
          //   ----------------------------------------CONSULTAR INFORMACION DE LOS PEDIDOS

          //console.log("lectura exitosa", excellist); // At this point, you get an array containing objects that need to be processed
        } catch (e) {
          console.log(e);
          return alert(
            'Fallo en la lectura, por favor intente cargar el archivo de excel de nuevo!'
          );
        }
      };
      fileReader.readAsBinaryString(files[0]);
      var input = document.getElementById('upload');
      input.value = '';
    },
  },
};
</script>
