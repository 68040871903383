<template>
  <mainMenu />
</template>

<script>
import mainMenu from '../components/mainmenu';

export default {
  name: 'Home',

  components: {
    mainMenu,
  },
};
</script>
