import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
import es from 'vuetify/lib/locale/es';
const vuetify = new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
  },
  theme: {
    themes: {
      light: {
        primary: '#031D6A',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
        clrInicioDeLabores: '#ccc',
        clrProspecto: '#ccc',
        clrNombramiento: '#ccc',
        clrPrimerPedido: '#ccc',
        clrSegundoPedido: '#ccc',
        clrReingreso: '#ccc',
        clrPosibleBaja: '#ccc',
        clrCobro: '#ccc',
        clrNiveles: '#ccc',
        clrZafiros: '#ccc',
        clrRegulares: '#ccc',
        clrAjuste: '#ccc',
        clrReunionesDeConsejeras: '#ccc',
        clrCorreteo: '#009688',
      },
    },
  },
});
export default vuetify;
