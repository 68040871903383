<template>
  <v-container class="grey lighten-3">
    <v-row class="mt-2">
      <v-col cols="3">
        <v-sheet rounded="lg">
          <v-list color="transparent">
            <v-list-item-group v-model="selection">
              <v-list-item
                v-for="n in links"
                :key="n.title"
                link
                @click="page = n.page"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ n.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>

            <v-divider></v-divider>
            <v-list-item disabled link color="grey lighten-4">
              <v-list-item-content>
                <v-list-item-title> Menu principal </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item disabled link color="grey lighten-4">
              <v-list-item-content>
                <v-list-item-title> Reportería </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item disabled link color="grey lighten-4">
              <v-list-item-content>
                <v-list-item-title> Usuarios </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item disabled link color="grey lighten-4">
              <v-list-item-content>
                <v-list-item-title> Sobrevivientes </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>

      <!-- datos generales -->
      <v-col v-if="page == 1">
        <v-sheet min-height="70vh" rounded="lg">
          <!--  -->
          <v-row>
            <v-col cols="12">
              <v-card class="" elevation="" max-width="">
                <v-card-text>
                  <div class="text-overline mb-4">Atención primaria</div>

                  <v-row>
                    <v-col>
                      <v-list-item-title class="text-h6 mb-1">
                        <v-avatar
                          class="profile"
                          color="purple"
                          size="120"
                          tile
                        >
                          <v-icon color="" dark size="58">mdi-account</v-icon>
                        </v-avatar>
                      </v-list-item-title>
                      <v-list-item-title class="font-weight-bold mb-1">
                        Nombre
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Dorothy Becker Tuc</v-list-item-subtitle
                      >
                      <v-list-item-title class="font-weight-bold mb-1">
                        Fecha de nacimiento
                      </v-list-item-title>
                      <v-list-item-subtitle>29/12/1990</v-list-item-subtitle>
                      <v-list-item-title class="font-weight-bold mb-1">
                        Teléfono
                      </v-list-item-title>
                      <v-list-item-subtitle>5695-2436</v-list-item-subtitle>
                      <v-list-item-title class="font-weight-bold mb-1">
                        DPI
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >1615-92041-0101</v-list-item-subtitle
                      >
                      <v-list-item-title class="font-weight-bold mb-1">
                        Seguimientos abiertos
                      </v-list-item-title>
                      <v-chip-group
                        v-model="selection"
                        active-class="green darken-3 white--text"
                        column
                      >
                        <v-chip>Área Psicológica - Motivo</v-chip>
                      </v-chip-group>
                    </v-col>
                    <v-col>
                      <v-list-item-title class="font-weight-bold mb-1"
                        >comunidad lingüística</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >Lorem ipsum dolor sit amet consectetur adipisicing
                        elit.</v-list-item-subtitle
                      >
                      <v-list-item-title class="font-weight-bold mb-1"
                        >pueblo o grupo étnico</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >Lorem ipsum dolor sit amet consectetur adipisicing
                        elit.</v-list-item-subtitle
                      >
                      <v-list-item-title class="font-weight-bold mb-1"
                        >identidad de género</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >Lorem ipsum dolor sit amet consectetur adipisicing
                        elit.</v-list-item-subtitle
                      >
                      <v-list-item-title class="font-weight-bold mb-1"
                        >Orientación sexual (en caso se pueda registrar el
                        dato)</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >Lorem ipsum dolor sit amet consectetur adipisicing
                        elit.</v-list-item-subtitle
                      >
                      <v-list-item-title class="font-weight-bold mb-1"
                        >Si posee alguna discapacidad y que
                        tipo</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >Lorem ipsum dolor sit amet consectetur adipisicing
                        elit.</v-list-item-subtitle
                      >
                      <v-list-item-title class="font-weight-bold mb-1"
                        >Religión (seleccionar de lista de
                        opciones)</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >Lorem ipsum dolor sit amet consectetur adipisicing
                        elit.</v-list-item-subtitle
                      >
                      <v-list-item-title class="font-weight-bold mb-1"
                        >Nacionalidad, que sea posible indicar
                        extranjeros</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >Lorem ipsum dolor sit amet consectetur adipisicing
                        elit.</v-list-item-subtitle
                      >
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary"
                    >Apertura de nuevo caso <v-icon color="">mdi-plus</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <!-- datos generales -->
      <v-col v-if="page == 2">
        <v-sheet min-height="70vh" rounded="lg">
          <!--  -->
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <div class="text-overline mb-4">
                    Atención primaria - servicios brindados a "Nombre_Persona"
                  </div>
                </v-card-title>
                <v-card-title primary-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar..."
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="desserts"
                  :search="search"
                >
                  <template v-slot:[`item.opciones`]="{ item }">
                    <v-btn :value="item.id" small elevation="" color="">
                      <v-icon color="">mdi-eye</v-icon>
                    </v-btn>
                  </template></v-data-table
                >
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    selection: 0,
    //
    links: [
      { class: 'active', title: 'Datos sobreviviente', page: 1 },
      { class: 'active', title: 'Asesorías', page: 2 },
    ],
    page: 1,
    //
    search: '',
    headers: [
      {
        text: 'ID del caso',
        align: 'start',
        filterable: false,
        value: 'id',
      },
      { text: 'Área', value: 'area' },
      { text: 'Motivo', value: 'motivo' },
      { text: 'Fecha de ingreso', value: 'fecha_ingreso' },
      { text: 'Fecha de ultimo seguimiento', value: 'fecha_ultimo_mov' },
      { text: 'Estado', value: 'estado' },
      { text: 'Ver detalle', value: 'opciones' },
    ],
    desserts: [
      {
        id: 1569584854,
        area: 'área psicológica',
        motivo: 'motivo',
        fecha_ingreso: '17 / 09 / 2020',
        fecha_ultimo_mov: '07 / 04 / 2021',
        estado: 'Abierto',
      },
      {
        id: 1251067065,
        area: 'área psicológica',
        motivo: '',
        fecha_ingreso: '10 / 03 / 2020',
        fecha_ultimo_mov: '14 / 09 / 2021',
        estado: 'Culminado',
      },
      {
        id: 2516821484,
        area: 'área atención primaria',
        motivo: 'Refugio',
        fecha_ingreso: '27 / 09 / 2020',
        fecha_ultimo_mov: '10 / 01 / 2021',
        estado: 'Culminado',
      },
      {
        id: 3052029194,
        area: 'área atención primaria',
        motivo: 'Albergue',
        fecha_ingreso: '15 / 11 / 2020',
        fecha_ultimo_mov: '20 / 03 / 2021',
        estado: 'Culminado',
      },
    ],
  }),
};
</script>
